<template>
  <svg
    viewBox="0 0 20 20">
    <path
      d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2
      C20,0.9 19.1,0 18,0 L18,0 Z M16,4 L16,7 L14,7 C13.4,7 13,7.4 13,8 L13,10 L16,10 L16,13 L13,13
      L13,20 L10,20 L10,13 L8,13 L8,10 L10,10 L10,7.5 C10,5.6 11.6,4 13.5,4 L16,4 L16,4 Z">
    </path>
  </svg>
</template>
